export const toysCategories = [
  {
    name: 'Trampolines',
    slug: {
      current: '/category/trampolines',
    },
    img: '/assets/img/jusplay/products-cat/Trampolines.webp',
  },
  {
    name: 'Kitchen Sets',
    slug: {
      current: '/category/kitchen-sets1',
    },
    img: '/assets/img/jusplay/products-cat/Kitchen.webp',
  },
  {
    name: 'Cars and Rides',
    slug: {
      current: '/category/cars-and-rides',
    },
    img: '/assets/img/jusplay/products-cat/battery.webp',
  },
  {
    name: 'Slides & Swings',
    slug: {
      current: '/category/slides-and-swings',
    },
    img: 'https://main-object-storage.blr1.cdn.digitaloceanspaces.com/18371a71965d1fbd8a6e948bd4c24aecf663a5e6-1500x1175.jpg',
  },
  {
    name: 'Ball Pools',
    slug: {
      current: '/category/ball-pools',
    },
    img: 'https://main-object-storage.blr1.cdn.digitaloceanspaces.com/Image%20resize%20(78).png',
  },
  {
    name: 'Wooden Wonders',
    slug: {
      current: '/category/wooden-wonders',
    },
    img: 'https://main-object-storage.blr1.cdn.digitaloceanspaces.com/wooden-wonders.webp',
  },
  {
    name: 'Travel Gear',
    slug: {
      current: '/category/travel-gear',
    },
    img: 'https://main-object-storage.blr1.cdn.digitaloceanspaces.com/travel-gears.webp',
  },
]
