import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import useDebounce from "../Debounce/debounce";
import { useRouter } from "next/router";
import client from "../../../../client";
import { each } from "underscore";

export const SearchHeader = () => {
  const router = useRouter();
  const [search, setSearch] = useState({ s: "" });
  const [products, setProducts] = useState([]);
  const [fixedNav, setFixedNav] = useState(false);
  const btnRef = useRef(null);

  //const [inputValue,setInputValue] = useState('')

  // const[productFound,setProductFound] = useState(false)

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearch) {
      // console.log(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    const query = `*[_type == "Toy" && !(_id in path('drafts.*')) && isDraft == false] | order(_createdAt desc)`;
    client.fetch(query).then((res) => {
      setProducts(res);
    });
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      router.push({
        pathname: "/search",
        query: search,
      });
    }
  };

  function replaceURL(url) {
    return url?.replace(
      "https://jusplaytoys.fra1.digitaloceanspaces.com",
      "https://main-object-storage.blr1.cdn.digitaloceanspaces.com",
    );
  }

  //console.log(products,'products')

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 10) {
      setFixedNav(true);
    } else {
      setFixedNav(false);
    }
  };

  return (
    <div
      className={`search-box ${fixedNav ? "blue" : "pink"}`}
      style={{
        display: [
          "/premium",
          "/basic",
          "/standard",
          "/category/",
          "/subscriptionSearch",
        ].includes(router.pathname)
          ? "none"
          : "flex",
      }}
    >
      <input
        type="text"
        placeholder="Search For Your Favourite Toy eg: Trampoline, Slide etc."
        className={`search-bar`}
        value={search.s}
        onChange={(e) => setSearch({ s: e.target.value })}
        onKeyDown={handleKeyPress}
        ref={btnRef}
      />
      <Link href={{ pathname: "/search", query: search }}>
        <div className="search-items">
          {products
            ?.filter((item) => {
              const searchTerm = search.s?.toLowerCase();
              const productName = item?.name?.toLowerCase();
              //console.log(productName)
              return (
                searchTerm &&
                productName?.includes(searchTerm) &&
                productName !== searchTerm
              );
            })
            .map((item) => (
              <Link href={`/product/${item.slug?.current}`}>
                <div
                  onClick={() => setSearch({ s: item.name })}
                  className="searchItem"
                >
                  <img
                    src={replaceURL(item.mainImage)}
                    alt={item.name}
                    className="search-item-img"
                  />
                  <span>{item.name}</span>
                </div>
              </Link>
            ))}
        </div>
      </Link>
    </div>
  );
};
