export const ageCategoriesData = [
  {
    name: '6 months-1 year',
    slug: {
      current: '/category/6-months-1-year',
    },
    img: 'https://main-object-storage.blr1.digitaloceanspaces.com/ages/6-months-to-1-year.webp',
  },
  {
    name: '1-3 Years',
    slug: {
      current: '/category/1-3-year',
    },
    img: 'https://main-object-storage.blr1.digitaloceanspaces.com/ages/1-3-years.webp',
  },
  {
    name: '3-6 Years',
    slug: {
      current: '/category/3-6-year',
    },
    img: 'https://main-object-storage.blr1.digitaloceanspaces.com/ages/3-6-years.webp',
  },
  {
    name: '6-8 Years',
    slug: {
      current: '/category/6-8-year',
    },
    img: 'https://main-object-storage.blr1.digitaloceanspaces.com/ages/6-8-years.webp',
  },
  {
    name: '8+ Years',
    slug: {
      current: '/category/8-plus-year',
    },
    img: 'https://main-object-storage.blr1.digitaloceanspaces.com/ages/8+years.webp',
  },
]
