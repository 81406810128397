import { Banner } from 'components/landing/Banner/Banner'
import { Discount } from 'components/landing/Discount/Discount'
import { Layout } from 'layout/Layout'
import client from '../../client'
import imageUrlBuilder from '@sanity/image-url'
import Head from 'next/head'
import { useState, useEffect, useContext, useRef } from 'react'
import { useRouter } from 'next/router'
import { Loader } from '../components/Loader/Loader'
import { UserContext } from '../context/UserContext'
import { FaVolumeMute } from 'react-icons/fa'
import { FaVolumeUp } from 'react-icons/fa'
import WhyUs from '../components/shared/Footer/WhyUs/WhyUs'
import { replaceURL } from '../components/utils/helpers'
import HowWeWork from '../components/Common/HowWeWork'
import { ageCategories } from '../constants/categories'
import { Testimonials } from '../components/shared/Tesimonials/Tesimonials'
import Testimonial from '../components/Testimonial'
import { toysCategories } from '../constants/toysCategories'
import {
  SlickArrowNext,
  SlickArrowPrev,
} from '../components/utils/SlickArrows/SlickArrows'
import Slider from 'react-slick'
import { testimonials } from '../constants/testimonials'
import starRating from '../../public/assets/img/jusplay/star-rating.png'
import { ageCategoriesData } from 'constants/ageCategories'

export default function Home() {
  // Get a pre-configured url-builder from your sanity client
  const builder = imageUrlBuilder(client)

  function urlFor(source) {
    return builder.image(source)
  }

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [categoryProducts, setCategoryProducts] = useState([])
  const [bookings, setBooking] = useState([])
  const [premiumProducts, setPremiumProducts] = useState([])
  const router = useRouter()
  const { cart, setCart } = useContext(UserContext)

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    gap: '20px',
    prevArrow: <SlickArrowPrev pageType='testimonials-carousel-prev' />,
    nextArrow: <SlickArrowNext pageType='testimonials-carousel-next' />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  // useEffect(() => {
  //   const handleStart = () => setLoading(true);
  //   const handleComplete = () => setLoading(false);
  //   router.events.on("routeChangeStart", handleStart);
  //   router.events.on("routeChangeComplete", handleComplete);
  //   router.events.on("routeChangeError", handleComplete);
  //   return () => {
  //     router.events.off("routeChangeStart", handleStart);
  //     router.events.off("routeChangeComplete", handleComplete);
  //     router.events.off("routeChangeError", handleComplete);
  //   };
  // }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const formattedCategories = ageCategories
        .map((category) => `"${category.slug}"`)
        .join(', ')
      const query = `*[count((categories[]->slug.current)[@ in [${formattedCategories}]]) > 0 && !(_id in *[_type == "ExculdedToy"].toy._ref)][0...10]`

      const result = await client.fetch(query)
      setCategoryProducts(result)
    }
    fetchCategories()
  }, [])

  useEffect(() => {
    const query = `*[_type == "Toy" && !(_id in path('drafts.**')) && isDraft == false] | order(_createdAt desc)`
    client.fetch(query).then((res) => {
      const discountedProducts = res.map((item) => {
        if (item.hasOwnProperty('discount')) {
          return {
            ...item,
            originalPrice: item.price,
            price: item.price - (item.discount / 100) * item.price,
          }
        } else {
          return { ...item, originalPrice: 0 }
        }
      })
      setProducts([...discountedProducts])
      setLoading(false)
    })
  }, [])

  const videoRef = useRef(null)
  const videoRefTwo = useRef(null)

  const [videoMuted, setVideoMuted] = useState(true)
  const [videoMutedTwo, setVideoMutedTwo] = useState(true)

  const toggleMute = () => {
    const video = videoRef.current
    video.muted = !video.muted
    setVideoMuted(video.muted)
  }

  const toggleMuteTwo = () => {
    const video = videoRefTwo.current
    video.muted = !video.muted
    setVideoMutedTwo(video.muted)
  }

  // Function to generate the date range and fetch bookings
  useEffect(() => {
    const today = new Date()
    const lastMonthToday = new Date(
      today.getFullYear(),
      today.getMonth() - 2,
      today.getDate()
    )

    const toDate = today.toISOString()
    const fromDate = lastMonthToday.toISOString()

    // Your existing GROQ query remains unchanged
    const query = `*[_type == "Booking" && from >= "${fromDate}" && to <= "${toDate}"] | order(unitsBooked desc) {
      unitsBooked,
      toy->{
        _id,
        "toyType": type,
      },
    }`

    client
      .fetch(query)
      .then((bookings) => {
        // Shuffle the bookings array to randomize
        const shuffledBookings = bookings.sort(() => 0.5 - Math.random())

        // Process and fetch toys based on shuffled bookings
        const popularProduct = shuffledBookings
          .slice(0, 10)
          .map((each) => each?.toy?._id)

        console.log('Random popular toys:', popularProduct)

        // Your existing logic to fetch toys by IDs
        const toyQuery = '*[_type == "Toy" && _id in $ids && isDraft == false]'

        const params = { ids: popularProduct }

        client
          .fetch(toyQuery, params)
          .then((toys) => {
            console.log(toys, 'Popular toys after shuffle')
            setBooking(toys)
          })
          .catch((error) => {
            console.error('Fetch error:', error)
          })
      })
      .catch((err) => console.error('Failed to fetch bookings:', err))
  }, []) // Dependency array empty to run once on component mount

  useEffect(() => {
    // For premium products with isDraft set to false
    const query = '*[_type == "Toy" && price > 1200 && isDraft == false]'

    client
      .fetch(query)
      .then((toys) => {
        // Randomly shuffle array (Fisher-Yates shuffle algorithm)
        const shuffled = toys.sort(() => 0.5 - Math.random())
        // Get sub-array of first N elements after shuffled
        let selected = shuffled.slice(0, 10)
        setPremiumProducts(selected)
      })
      .catch((error) => {
        console.error('Fetch error:', error)
      })
  }, [])

  useEffect(() => {
    const observerOptions = {
      root: null, // observing for viewport
      threshold: 0.5, // trigger callback when 50% of the element is visible
    }

    // Observer callback function
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        // Check if the video is visible
        if (entry.isIntersecting) {
          // Play video if it's visible
          entry.target.play()
        } else {
          // Pause video if it's not visible
          entry.target.pause()
        }
      })
    }

    // Create two observers for each video
    const observer1 = new IntersectionObserver(
      observerCallback,
      observerOptions
    )
    const observer2 = new IntersectionObserver(
      observerCallback,
      observerOptions
    )

    // Get video elements
    const video1 = videoRef.current
    const video2 = videoRefTwo.current

    // Observe the videos
    if (video1) observer1.observe(video1)
    if (video2) observer2.observe(video2)

    // Clean up function to disconnect observers when the component unmounts
    return () => {
      observer1.disconnect()
      observer2.disconnect()
    }
  }, [])

  return (
    <>
      <Head>
        {/* Gtag */}

        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-5LS3FRLR'
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-5LS3FRLR');
            `,
          }}
        />

        <title>
          Jusplaytoys – Toy Renting Library / Soft Playarea Setups / Birthday
          Party Planner
        </title>
        <meta
          property='og:url'
          content={`https://www.jusplaytoys.com${router.asPath}`}
        />
        <link
          rel='canonical'
          href={`https://www.jusplyatoys.com${router.asPath}`}
        />
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content='Jusplaytoys – Toy Renting Library / Soft Playarea Setups / Birthday Party Planner'
        />
        <meta
          property='og:image'
          content='https://www.jusplaytoys.com/assets/img/jusplay/jusplay-4.jpg'
        />
      </Head>
      {loading ? (
        <Loader />
      ) : (
        <Layout pageType='index'>
          <Banner />
          {/*<BrandLogo />*/}

          <div
            style={{
              background: 'transparent',
              display: 'flex',
              padding: '1rem',
            }}
            className='video-home-div'
          >
            <div className={'videoContainer'}>
              <video
                ref={videoRefTwo}
                className='video-tag'
                autoPlay
                muted
                loop
              >
                <source
                  src='https://main-object-storage.blr1.cdn.digitaloceanspaces.com/WhatsApp%20Video%202024-03-18%20at%2014.18.28.mp4'
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
              <span className={'muteButton'} onClick={toggleMuteTwo}>
                {videoMutedTwo ? (
                  <FaVolumeMute size={20} />
                ) : (
                  <FaVolumeUp size={20} />
                )}
                <span>{!videoMutedTwo ? 'ON' : 'OFF'}</span>
              </span>
            </div>
          </div>
          <Discount />
          <WhyUs />
          <HowWeWork />
          {/*<Advantage />*/}

          <div>
            <div className='toys-adored-customer gradient-title'>
              Explore Top Selling Toy Categories
            </div>
            <div className='shop-main__items products-list'>
              {toysCategories?.map((product) => (
                <Product key={product._id} product={product} type='category' />
              ))}
            </div>
            <div className='toys-adored-customer gradient-title'>
              Explore Toys By Age Categories
            </div>
            <div className='shop-main__items products-list'>
              {ageCategoriesData?.map((product) => (
                <Product key={product._id} product={product} type='category' />
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                className='toys-adored-customer gradient-title'
                style={{ padding: '30px', paddingTop: '60px' }}
              >
                Don't just take our word
              </div>
              <div
                className='toys-adored-customer gradient-title'
                style={{
                  padding: '0px',
                  paddingBottom: '10px',
                  marginTop: '-10px',
                  fontSize: '18px',
                  fontWeight: '600',
                }}
              >
                See what parents have to say about Jusplay
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#84C4BB',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    fontSize: '50px',
                    fontWeight: 600,
                  }}
                >
                  4.9
                </span>
                <img
                  src='https://i.ibb.co/85VqMMF/star-rating-edited.png'
                  style={{ width: 'fit-content' }}
                />
              </div>
            </div>
            <div
              className='shop-main__items'
              style={{
                marginTop: '50px',
                // marginBottom: "-80px",
                paddingLeft: '40px',
                paddingRight: '40px',
              }}
            >
              <div style={{ width: '100%' }}>
                <Slider {...settings}>
                  {testimonials.map((x, idx) => (
                    <div style={{ width: '100%' }}>
                      <Card key={idx} customer={x} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/*<Info />*/}
          {/*<LatestNsews />*/}
          {/*<Subscribe />*/}
        </Layout>
      )}
    </>
  )
}

const Card = ({ customer }) => {
  return (
    <>
      <div
        className='why-us testimonial'
        style={{
          width: '100% !important',
          position: 'relative',
          margin: '20px 8px',
          boxShadow:
            '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
        }}
      >
        <p>{customer.comment}</p>
        <div>
          <strong>{customer.name}</strong>
        </div>
        <div style={{ position: 'absolute', top: '-20px' }}>
          <img src={customer.img} style={{ width: '60px', height: '60px' }} />
        </div>
      </div>
    </>
  )
}

const Product = ({ type = 'normalProduct', product, className }) => {
  const { name, price, slug, mainImage } = product
  console.log('product', product)
  return (
    <a
      href={`${type === 'normalProduct' ? '/product/' : ''}${slug.current}`}
      className='single-product-wrapper'
    >
      <>
        <div className='single-product-detail'>
          <img
            src={type === 'normalProduct' ? replaceURL(mainImage) : product.img}
            alt={name}
            className='h-full'
          />
        </div>
        {name && (
          <p
            className='single-product-title'
            style={{
              fontFamily: type === 'category' && `'Itim', sans-serif`,
              fontSize: type === 'category' && '20px',
              margin: type === 'category' && '0 0 10px 0',
            }}
          >
            {name}
          </p>
        )}
        <div className='single-product-price'>
          {price && <p>₹{price} / Week</p>}
        </div>
      </>
    </a>
  )
}
